<template>
  <div :class="['fatca-card', { disabled: !isActive }]">
    <div class="row">
      <p class="fond-head">
        {{ title }}
      </p>
      <v-icon
        v-if="downloadLink"
        @click="$emit('download')"
        size="xLarge"
      >
        {{ getIcon(downloadLink) }}
      </v-icon>
      <v-icon
        v-else-if="status === 'Отклонено'"
        :hovering="false"
        class="alert"
        size="xLarge"
        >requires-updating
      </v-icon>
    </div>
    <!--        for large screens           -->
    <div class="fatca-card__body">
      <!--            titles          -->
      <div class="card-row">
        <div class="titles">
          <p>Дата подписания:</p>
        </div>
        <div class="datas">
          <p class="data">{{ singDate || "singDate" }}</p>
        </div>
      </div>
      <div class="card-row" v-if="fundName">
        <div class="titles">
          <p>Фонд:</p>
        </div>
        <div class="datas">
          <p class="data">{{ fundName || "fundName" }}</p>
        </div>
      </div>
      <div class="card-row" v-if="specNumber">
        <div class="titles">
          <p>СпецРег:</p>
        </div>
        <div class="datas">
          <p class="data">{{ specNumber || "specNumber" }}</p>
        </div>
      </div>
      <div class="card-row">
        <div class="titles">
          <p>Статус:</p>
        </div>
        <div class="datas">
          <p :class="['data', { active: status === 'Требует актуализации' }]">
            {{ status || "status" }}
          </p>
        </div>
      </div>
      <div class="card-row" v-if="action">
        <div class="titles">
          <p>Событие:</p>
        </div>
        <div class="datas">
          <p class="data">{{ action || "action" }}</p>
        </div>
      </div>
      <div class="card-row" v-if="paOpeningDate">
        <div class="titles">
          <p>Дата открытия ЛС:</p>
        </div>
        <div class="datas">
          <p class="data">{{ paOpeningDate || "paOpeningDate" }}</p>
        </div>
      </div>
      <div class="card-row" v-if="personalAccount">
        <div class="titles">
          <p>Номер ЛС:</p>
        </div>
        <div class="datas">
          <p class="data">{{ personalAccount || "personalAccount" }}</p>
        </div>
      </div>
      <div class="card-row" v-if="application">
        <div class="titles">
          <p>Заявление:</p>
        </div>
        <div class="datas">
          <p class="data" @click="save(application.link)">
            {{ application.title || "application" }}
          </p>
        </div>
      </div>
      <div class="card-row" v-if="payDocs">
        <div class="titles">
          <p>Документы для оплаты:</p>
        </div>
        <div class="datas">
          <p class="data">
            <v-icon
              @click="
                $emit('downloadDoc', {
                  id: payDocs,
                  name: 'Документы для оплаты',
                  type: 'zip',
                })
              "
              >zip
            </v-icon>
          </p>
        </div>
      </div>
      <div class="card-row" v-if="noticeDocs">
        <div class="titles">
          <p>Извещение:</p>
        </div>
        <div class="datas">
          <p class="data">
            <v-icon
              @click="
                $emit('download', noticeDocs)
              "
              >{{noticeDocs.origin_name.split(".")[1].match("pdf") ? "note-blank" : "note"}}
            </v-icon>
          </p>
        </div>
      </div>
      <div class="card-row" v-if="noteKiDocs">
        <div class="titles">
          <p>Уведомление о признании КИ:</p>
        </div>
        <div class="datas">
          <p class="data">
            <v-icon
              @click="
                $emit('download', noteKiDocs)
              "
              >{{noteKiDocs.origin_name.split(".")[1].match("pdf") ? "note-blank" : "note"}}
            </v-icon>
          </p>
        </div>
      </div><div class="card-row" v-if="noteDocs">
        <div class="titles">
          <p>Уведомление:</p>
        </div>
        <div class="datas">
          <p class="data">
            <v-icon
              @click="
                $emit('downloadDoc', {
                  id: noteDocs,
                  name: 'Уведомление',
                  type: 'pdf',
                })
              "
              >note-blank
            </v-icon>
          </p>
        </div>
      </div>
      <div class="card-row" v-if="notification">
        <div class="titles">
          <p>Уведомление об открытии:</p>
        </div>
        <div class="datas">
          <p class="data" @click="save(notification.link)">
            {{ notification.title || "noteDocs" }}
          </p>
        </div>
      </div>
    </div>
    <!--            for small screens           -->
    <div class="fatca-card__body-small">
      <div class="data-row">
        <p>Дата подписания:</p>
        <p class="data">{{ singDate || "singDate" }}</p>
      </div>
      <div class="data-row" v-if="specNumber">
        <p>СпецРег:</p>
        <p class="data">{{ specNumber || "specNumber" }}</p>
      </div>
      <div class="data-row" v-if="fundName">
        <p>Фонд:</p>
        <p class="data">{{ fundName || "fundName" }}</p>
      </div>
      <div class="data-row">
        <p>Статус:</p>
        <p class="data">{{ status || "status" }}</p>
      </div>
      <div class="data-row">
        <p v-if="action">Событие:</p>
        <p class="data" v-if="action">{{ action }}</p>
      </div>
      <div v-if="paOpeningDate" class="data-row">
        <p>Дата открытия ЛС:</p>
        <p class="data" v-if="paOpeningDate">{{ paOpeningDate }}</p>
      </div>
      <div v-if="personalAccount" class="titles">
        <p>Номер ЛС:</p>
        <p class="data" v-if="personalAccount">{{ personalAccount }}</p>
      </div>
      <div v-if="application" class="titles">
        <p>Заявление:</p>
        <p class="data" v-if="application" @click="save(application.link)">
          {{ application.title }}
        </p>
      </div>
      <div v-if="payDocs" class="titles">
        <p>Документы для оплаты:</p>
        <p class="data" v-if="payDocs">
          <v-icon
            @click="
              $emit('downloadDoc', {
                id: payDocs,
                name: 'Документы для оплаты',
                type: 'zip',
              })
            "
            >zip
          </v-icon>
        </p>
      </div>
      <div v-if="noticeDocs" class="titles">
        <p>Извещение:</p>
        <p class="data" v-if="noticeDocs">
          <v-icon
            @click="
              $emit('download', noticeDocs)
            "
            >{{noticeDocs.origin_name.split(".")[1].match("pdf") ? "note-blank" : "note"}}
          </v-icon>
        </p>
      </div>
      <div v-if="noteKiDocs" class="titles">
        <p>Уведомление о признании КИ:</p>
        <p class="data" v-if="noteKiDocs">
          <v-icon
            @click="
              $emit('download', noteKiDocs)
            "
            >{{noteKiDocs.origin_name.split(".")[1].match("pdf") ? "note-blank" : "note"}}
          </v-icon>
        </p>
      </div>
      <div v-if="noteDocs" class="titles">
        <p>Уведомление:</p>
        <p class="data" v-if="noteDocs">
          <v-icon
            @click="
              $emit('downloadDoc', {
                id: noteDocs,
                name: 'Уведомление',
                type: 'pdf',
              })
            "
            >note-blank
          </v-icon>
        </p>
      </div>
      <div v-if="notification" class="titles">
        <p>Уведомление об открытии:</p>
        <p class="data" v-if="notification" @click="save(notification.link)">
          {{ notification.title }}
        </p>
      </div>
    </div>
    <!--            end small screens fragment      -->
    <div class="active message" v-if="adminComment && status === 'Отклонено'">
      <p>
        {{ adminComment }}
      </p>
    </div>
    <div
      class="active message"
      v-if="messages && messages.length > 0"
      style="text-align: center"
    >
      <p v-for="(message, index) in messages" :key="`message-${index}`">
        {{ message.message }}
      </p>
    </div>
  </div>
</template>

<script>
import VIcon from "../icons/VIcon";

export default {
  name: "RwmDocumentCard",
  components: { VIcon },
  props: {
    title: String,
    downloadLink: [Object, Boolean, null],
    isActive: {
      type: Boolean,
      default: false,
    },
    message: [String, null],
    messages: [Array, null],
    adminComment: [String, null],
    singDate: [String, Number],
    status: String,
    action: String,
    issue: [String, Number],
    notification: Object,
    application: Object,
    personalAccount: String,
    paOpeningDate: String,
    specNumber: [String, Number],
    payDocs: [String, Number],
    noteDocs: [String, Number],
    noticeDocs: [Object, String, Number],
    noteKiDocs: [Object, String, Number],
    fundName: String,
  },
  methods: {
    save(link) {
      this.$emit("click", link);
    },
    getIcon(link) {
      if (link?.origin_name) {
        return link?.origin_name.split(".")[1] === "pdf"
          ? "note-blank"
          : "note";
      } else if (link) {
        return "note-blank";
      } else return "note";
    },
  },
};
</script>

<style scoped lang="scss">
.fatca-card {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
  padding: 20px 32px;
  border: 1px solid $RWM-grey-super-light;
  @media screen and (min-width: 1280px) {
    min-width: 450px;
    max-width: calc(50% - 20px);
  }

  & .alert {
    color: $RWM-red;
  }

  &.disabled {
    opacity: 0.65;
  }

  & .row {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 24px;
    justify-content: space-between;
    align-items: center;
    color: $RWM-dark;
    flex-direction: row;

    & .fond-head {
      color: $RWM-grey;
    }
  }

  &__body {
    display: none;
    color: $RWM-grey;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 26px;
    @media screen and (min-width: 965px) {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
    }

    & .card-row {
      display: flex;
      column-gap: 32px;

      & .titles,
      & .datas {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        width: 50%;
        max-width: calc(50% - 16px);
      }

      & .titles {
        text-align: right;
        min-width: 180px;
      }

      & .datas {
        text-align: left;
        min-width: 180px;

        & .data {
          min-height: 26px;
          display: flex;
          align-items: center;
        }
      }
    }

    &-small {
      display: flex;
      color: $RWM-grey;
      font-style: normal;
      font-weight: 500;
      font-size: 19px;
      flex-direction: column;
      row-gap: 24px;
      text-align: center;
      @media screen and (min-width: 965px) {
        display: none;
      }
    }
  }

  & .active {
    color: $RWM-red;
  }

  & .message {
    width: 100%;
    text-align: left;
    cursor: pointer;

    overflow: hidden;

    & p {
      font-weight: 500;
      font-size: 19px;
      line-height: 26px;
      overflow: hidden;

      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:hover {
      max-height: 1000px;

      & p {
        white-space: normal;
      }
    }
  }

  & .message {
    max-height: 26px;
    transition: max-height 300ms;
  }
}
</style>
